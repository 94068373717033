//import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import {
  BButton,
  BSpinner,
  BNav,
  BNavItem,
  BCard,
  BCardHeader,
  BCardBody
} from "bootstrap-vue";

export default {
  name: "workadminpage",
  data() {
    return {};
  },
  validations: {
    form: {
      address: { required }
    }
  },
  components: {
    "b-button": BButton,
    "b-spinner": BSpinner,
    "b-nav": BNav,
    "b-nav-item": BNavItem,
    "b-card": BCard,
    "b-card-header": BCardHeader,
    "b-card-boody": BCardBody
  },
  created() {
    console.info("CREATED WorkAdmin page");
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {
    //console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);
  },
  watch: {},
  computed: {
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    /*id: () => {
      return this.$route.params.id;
    }*/
  },
  methods: {}
};
