import { render, staticRenderFns } from "./adminroutes.html?vue&type=template&id=084d2441&scoped=true&"
import script from "./adminroutes.js?vue&type=script&lang=babel&"
export * from "./adminroutes.js?vue&type=script&lang=babel&"
import style0 from "./adminroutes.vue?vue&type=style&index=0&id=084d2441&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084d2441",
  null
  
)

export default component.exports