//import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import {
  postUser,
  getUserProfile,
  getUserList
} from "@/services/api/workUsers";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BInputGroupText,
  BInputGroupAppend
} from "bootstrap-vue";

export default {
  name: "adminnotifications",
  data() {
    return {
      columnDefs: [
        {
          sortable: true,
          filter: true,
          field: "zipCodes",
          headerName: "Postnr. intervall",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "maxLength",
          headerName: "Max lastemeter (cm)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "maxWeight",
          headerName: "Maks vekt (kg)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "maxWidth",
          headerName: "Maks bredde (cm)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "maxHeight",
          headerName: "Maks høyde (cm)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "sendTo",
          headerName: "Send til",
          type: "string",
          editable: true
        }
      ],
      rows: [
        {
          id: "3243424315773333423",
          zipCodes: "0100-1395",
          maxLength: 1200,
          maxWeight: 5000,
          maxWidth: 250,
          maxHeight: 250,
          sendTo: "Conrad"
        },
        {
          id: "3243424315773333423",
          zipCodes: "4500 - 3100",
          maxLength: 300,
          maxWeight: 1100,
          maxWidth: 200,
          maxHeight: 200,
          sendTo: "Alfred, Conrad"
        }
      ]
    };
  },
  validations: {
    form: {
      address: { required }
    }
  },
  components: {
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText
  },
  created() {
    console.info("CREATED workUsers form");
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {
    console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);
  },
  watch: {},
  computed: {
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    /*id: () => {
      return this.$route.params.id;
    }*/
  },
  methods: {
    postUser,
    getUserProfile,
    getUserList
  }
};
