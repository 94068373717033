//import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import {
  postUser,
  getUserProfile,
  getUserList
} from "@/services/api/workUsers";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BInputGroupText,
  BInputGroupAppend
} from "bootstrap-vue";

export default {
  name: "adminusers",
  data() {
    return {
      columnDefs: [
        {
          sortable: true,
          filter: true,
          field: "name",
          headerName: "Navn",
          editable: true
        },
        {
          sortable: true,
          filter: true,
          field: "email",
          headerName: "E-post",
          editable: true,
          maxlength: 30
        },
        {
          sortable: true,
          filter: false,
          field: "lastlogin",
          headerName: "Sist innlogget",
          type: "date",
          format: "dd.MM.yyyy",
          editable: false
        },
        {
          sortable: true,
          filter: false,
          field: "mobile",
          headerName: "Mobilnr.",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "language",
          headerName: "Språk",
          type: "select",
          editable: true,
          selectOptions: [
            { value: "nb-NO", text: "Norsk" },
            { value: "en-UK", text: "Engelsk" }
          ]
        },
        {
          sortable: false,
          filter: false,
          field: "roles",
          headerName: "Roller",
          type: "link"
        },
        {
          sortable: true,
          filter: false,
          field: "isActive",
          headerName: "Aktiv ?",
          type: "boolean",
          editable: true
        }
      ],
      rows: [
        {
          id: "3243424315777425423",
          name: "Conrad",
          email: "conrad@waldvogel.com",
          lastlogin: "2020-04-12",
          mobile: "+47 93443367",
          language: "Norsk",
          roles: "Admin",
          isActive: true
        },
        {
          id: "3243424315425423",
          name: "Alfred",
          email: "Alfred@waldvogel.com",
          lastlogin: "2020-04-09",
          mobile: "+47 99342256",
          language: "Norsk",
          roles: "User",
          isActive: true
        }
      ]
    };
  },
  validations: {
    form: {
      address: { required }
    }
  },
  components: {
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText
  },
  created() {
    console.info("CREATED workUsers form");
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {
    console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);
  },
  watch: {},
  computed: {
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    /*id: () => {
      return this.$route.params.id;
    }*/
  },
  methods: {
    postUser,
    getUserProfile,
    getUserList
  }
};
