//import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import {
  postUser,
  getUserProfile,
  getUserList
} from "@/services/api/workUsers";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BInputGroupText,
  BInputGroupAppend
} from "bootstrap-vue";

export default {
  name: "adminvehicles",
  data() {
    return {
      columnDefs: [
        {
          sortable: true,
          filter: true,
          field: "typeName",
          headerName: "Type",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "numberOf",
          headerName: "Antall",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "capacityLength",
          headerName: "Maks lastemeter (cm)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "capacityWeight",
          headerName: "Maks vekt (kg)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "capacityWidth",
          headerName: "Maks bredde (cm)",
          type: "number",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "capacityHeight",
          headerName: "Maks høyde (cm)",
          type: "number",
          editable: true
        },
        {
          sortable: false,
          filter: false,
          field: "icon",
          headerName: "Ikon",
          type: "select",
          editable: true,
          selectOptions: [
            { value: "semi", text: "Semi trailer" },
            { value: "truck", text: "Varebil" },
            { value: "express-small", text: "Liten budbil" },
            { value: "truck-open", text: "Lastebil" },
            { value: "drone", text: "Drone" }
          ]
        }
      ],
      rows: [
        {
          id: "3243424315773333423",
          typeName: "Varebil",
          numberOf: 12,
          capacityLength: 500,
          capacityWeight: 2000,
          capacityWidth: 250,
          capacityHeight: 220,
          icon: "truck"
        },
        {
          id: "3243424315773333423",
          typeName: "Semi trailer",
          numberOf: 3,
          capacityLength: 1100,
          capacityWeight: 6000,
          capacityWidth: 300,
          capacityHeight: 320,
          icon: "semi"
        }
      ]
    };
  },
  validations: {
    form: {
      address: { required }
    }
  },
  components: {
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText
  },
  created() {
    console.info("CREATED workUsers form");
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {
    console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);
  },
  watch: {},
  computed: {
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    /*id: () => {
      return this.$route.params.id;
    }*/
  },
  methods: {
    postUser,
    getUserProfile,
    getUserList
  }
};
