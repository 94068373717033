//import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import {
  postUser,
  getUserProfile,
  getUserList
} from "@/services/api/workUsers";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BInputGroupText,
  BInputGroupAppend
} from "bootstrap-vue";

export default {
  name: "adminroutes",
  data() {
    return {
      columnDefs: [
        {
          sortable: true,
          filter: true,
          field: "routeFrom",
          headerName: "Fra",
          editable: true
        },
        {
          sortable: true,
          filter: true,
          field: "routeTo",
          headerName: "Til",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "vehicleType",
          headerName: "Kjøretøytype",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "weekdays",
          headerName: "Ukedager",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "notRegularly",
          headerName: "ikke regelmessig",
          type: "boolean",
          editable: true
        },
        {
          sortable: true,
          filter: false,
          field: "nextDate",
          headerName: "Neste tur",
          type: "date",
          editable: true
        }
      ],
      rows: [
        {
          id: "3243424315773333423",
          routeFrom: "Oslo",
          routeTo: "Bergen",
          vehicleType: "Semitrailer",
          weekdays: "Mandag, Torsdag, Fredag",
          notRegularly: true,
          nextDate: "2021-04-15"
        },
        {
          id: "3243424315773333423",
          routeFrom: "Kristiansand",
          routeTo: "Stavanger",
          vehicleType: "Varebil",
          weekdays: "Onsdag, Fredag",
          notRegularly: false,
          nextDate: "2021-04-21"
        }
      ]
    };
  },
  validations: {
    form: {
      address: { required }
    }
  },
  components: {
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText
  },
  created() {
    console.info("CREATED workUsers form");
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {
    console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);
  },
  watch: {},
  computed: {
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    /*id: () => {
      return this.$route.params.id;
    }*/
  },
  methods: {
    postUser,
    getUserProfile,
    getUserList
  }
};
